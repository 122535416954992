import { SUBSCRIPTION_CHANGE_TYPE } from '../../../../../../constants/subscription';
import { COUNTRY_CODE } from '../../../../../../../constants/country';
import { countryHasSuperboostEnabled } from '../../../../../../../selectors/config';
import { getFeatureFlagForCountrySelector } from '../../../../../../../utils/featuresFlags/selectors';
import { REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE } from './constansts';
import { MEXICO_TYPE_OF_CLIENT } from '../../../../../CreatePublisher/constants';
import { isLATAM } from '../../../../../../../utils/countries';
import {
  oneOf,
  userIsSalesManagerSelector,
  userIsSuperadminSelector,
} from '../../../../../../../selectors/user';
import { plansSelector } from '../../../../selectors';
import { WASI_PLANS_STATE_REGION } from '../../../../../constants';
import { CREDIT_CARD } from '../../../../../../../constants/paymentMethod';
import { normalizeTypeOfClient } from '../../../../../../utils/normalizeTypeOfClient';
import { filterPlansByTypeOfClient } from '../../../../../../utils/plansFinder';

export function selectedBoostingLimitSelector(state) {
  return selectedOptionsSelector(state)?.boostingLimit;
}

export function selectedSuperboostingLimitSelector(state) {
  return selectedOptionsSelector(state)?.superboostingLimit;
}

export function selectedPublisherCountryCodeSelector(state) {
  return selectedPublisherSelector(state)?.countryCode;
}

export function selectedPublisherIsFromLATAM(state) {
  return isLATAM(selectedPublisherCountryCodeSelector(state));
}

export const canUseAdditionalMonthCheckboxSelector = oneOf(
  userIsSuperadminSelector,
  userIsSalesManagerSelector
);

export function availablePaymentMethodsSelector(state) {
  return (
    requestSubscriptionChangeModalSelector(state)?.availablePaymentMethods ?? []
  );
}

export function selectedPublisherIsLoadedSelector(state) {
  return (
    requestSubscriptionChangeModalSelector(state)?.selectedPublisher != null
  );
}

export function selectedPublisherNextBillingDateSelector(state) {
  return selectedPublisherSubscriptionSelector(state)?.nextBillingDate;
}

export function showPublishesInThailandPropertySelector(state) {
  return selectedPublisherCountryCodeSelector(state) === COUNTRY_CODE.THAILAND;
}

export function selectedBillingCycleSelector(state) {
  return selectedOptionsSelector(state)?.billingCycle;
}

export function selectedPlanIdSelector(state) {
  return selectedOptionsSelector(state)?.planId;
}

function selectedPlanSelector(state) {
  const plans = plansSelector(state);
  const selectedPlanId = selectedPlanIdSelector(state);
  return plans.find((plan) => plan.id === selectedPlanId);
}

export function selectedPaymentMethodSelector(state) {
  return selectedOptionsSelector(state)?.paymentMethod;
}

export function selectedAdditionalMonthSelector(state) {
  return selectedOptionsSelector(state)?.additionalMonth;
}

export function disabledAdditionalMonthSelector(state) {
  const billingCycleInMonths = selectedBillingCycleSelector(state);
  const countryCode = selectedPublisherCountryCodeSelector(state);
  return (
    billingCycleInMonths === 1 ||
    (billingCycleInMonths === 3 && countryCode !== COUNTRY_CODE.CHILE)
  );
}

export function summarySelector(state) {
  return requestSubscriptionChangeModalSelector(state)?.summary;
}

export function totalPriceAmountSelector(state) {
  return summarySelector(state)?.totalPrice?.amount;
}

export function isPriceLowerThanMinimumSelector(state) {
  return summarySelector(state)?.isPriceLowerThanMinimum;
}

export function isPriceHigherThanMaximumSelector(state) {
  return summarySelector(state)?.isPriceHigherThanMaximum;
}

export function subscriptionChangeTypeSelector(state) {
  return requestSubscriptionChangeModalSelector(state)?.type;
}

export function selectedPublisherSubscriptionSelector(state) {
  return selectedPublisherSelector(state)?.subscription;
}

export function selectedPublisherSuperboostSelector(state) {
  return selectedPublisherSelector(state)?.superboost;
}

export function plansByMaxBoostablePropertiesSelector(state) {
  const maxBoostablePropsSelected = selectedBoostingLimitSelector(state);
  const plans = plansSelector(state).filter(
    ({ maxBoostableProperties }) =>
      maxBoostableProperties === maxBoostablePropsSelected
  );
  return plans
    .filter((plan) => plan.active)
    .filter((plan) => plan.price > 0)
    .sort((plan, anotherPlan) => plan.price - anotherPlan.price);
}

export function maxBoostablePropertiesSelector(state) {
  const plans = filterPlansByTypeOfClient(
    selectedPublisherCountryCodeSelector(state),
    selectedTypeOfClientSelector(state),
    plansSelector(state)
  );

  return Array.from(
    new Set(
      plans
        .filter((plan) => plan.active)
        .filter((plan) => plan.price > 0)
        .map(({ maxBoostableProperties: maxProp }) => maxProp)
        .sort((maxProp, anotherMaxProp) => maxProp - anotherMaxProp)
    )
  );
}

export const subscriptionChangeRequestTypeSelector = (state) =>
  requestSubscriptionChangeModalSelector(state)?.type;

export function subscriptionChangeRequestTypeValueForSelectSelector(state) {
  const type = subscriptionChangeTypeSelector(state);
  if (type === SUBSCRIPTION_CHANGE_TYPE.DOWNGRADE_NEXT_CYCLE) {
    return SUBSCRIPTION_CHANGE_TYPE.UPSELL_NEXT_CYCLE;
  }

  return type;
}

export function publishesInThailandPropertyInputValueSelector(state) {
  return selectedOptionsSelector(state)?.publishesInThailandProperty;
}

function selectedPublisherSelector(state) {
  return requestSubscriptionChangeModalSelector(state)?.selectedPublisher;
}

function selectedOptionsSelector(state) {
  return requestSubscriptionChangeModalSelector(state)?.selectedOptions;
}

export function requestButtonShouldBeDisabledSelector(state) {
  const submitShouldBeDisabled =
    requestSubscriptionChangeModalSelector(state)?.disableSubmitButton;
  if (submitShouldBeDisabled) {
    return true;
  }
  const subscriptionChangeType = subscriptionChangeTypeSelector(state);
  const availablePaymentMethods = availablePaymentMethodsSelector(state);
  const selectedPaymentMethod = selectedPaymentMethodSelector(state);
  const requestInProgress = requestInProgressSelector(state);
  const upsellCurrentOrNewInGracePeriod =
    (subscriptionChangeType === SUBSCRIPTION_CHANGE_TYPE.UPSELL_CURRENT_CYCLE ||
      subscriptionChangeType === SUBSCRIPTION_CHANGE_TYPE.UPSELL_NEW_CYCLE) &&
    selectedPublisherIsInGracePeriodSelector(state);
  const wasiPlanId = wasiPlanIdSelector(state);
  const wasiAccountEmail = wasiAccountEmailSelector(state);
  const baseFinalPrice = subscriptionFinalPriceSelector(state);
  const superboostFinalPrice = superboostFinalPriceSelector(state);
  const baseCustomDiscount = subscriptionCustomDiscountSelector(state);
  const superboostCustomDiscount = superboostCustomDiscountSelector(state);
  const isPriceLowerThanMinimum = isPriceLowerThanMinimumSelector(state);
  const isPriceHigherThanMaximum = isPriceHigherThanMaximumSelector(state);

  return (
    requestInProgress ||
    selectedPlanIdSelector(state) === '' ||
    selectedPlanIdSelector(state) === 'free' ||
    selectedPublisherSelector(state) == null ||
    isInvalidNumber(baseFinalPrice) ||
    isInvalidNumber(superboostFinalPrice) ||
    isInvalidNumber(baseCustomDiscount) ||
    isInvalidNumber(superboostCustomDiscount) ||
    totalPriceAmountSelector(state) <= 0 ||
    isPriceLowerThanMinimum ||
    isPriceHigherThanMaximum ||
    !availablePaymentMethods.some(({ id }) => id === selectedPaymentMethod) ||
    upsellCurrentOrNewInGracePeriod ||
    hasWasiAccountErrorSelector(state) ||
    Boolean(wasiPlanId && !wasiAccountEmail)
  );
}

function isInvalidNumber(value) {
  return value && Number.isNaN(parseFloat(value));
}

export function buttonLabelSelector(state) {
  if (requestButtonShouldBeDisabledSelector(state)) {
    return 'REQUEST CHANGE';
  }

  const subscriptionChangeType = subscriptionChangeTypeSelector(state);

  const labels = {
    [SUBSCRIPTION_CHANGE_TYPE.UPSELL_CURRENT_CYCLE]: 'REQUEST UPSELL',
    [SUBSCRIPTION_CHANGE_TYPE.UPSELL_NEW_CYCLE]: 'REQUEST UPSELL',
    [SUBSCRIPTION_CHANGE_TYPE.UPSELL_NEXT_CYCLE]: 'REQUEST UPSELL',
    [SUBSCRIPTION_CHANGE_TYPE.DOWNGRADE_NEXT_CYCLE]: 'REQUEST DOWNGRADE',
    [SUBSCRIPTION_CHANGE_TYPE.NEW_CONTRACT]: 'REQUEST NEW CONTRACT',
  };

  return labels[subscriptionChangeType] ?? 'REQUEST CHANGE';
}

function requestInProgressSelector(state) {
  return Boolean(requestSubscriptionChangeModalSelector(state)?.inProgress);
}

export function publisherHasSuperboostContractedSelector(state) {
  const publisher = selectedPublisherSelector(state);
  return publisher?.hasSuperboostContracted === true;
}

export function requestSuperboostChangeAvailableSelector(state) {
  const countryCode = selectedPublisherCountryCodeSelector(state);
  return (
    getFeatureFlagForCountrySelector(
      state,
      'SUPERBOOST_ALL_COUNTRIES_3119',
      countryCode
    ) || countryHasSuperboostEnabled(countryCode)
  );
}

export function selectedPublisherIsFreeSelector(state) {
  const selectedPublisher = selectedPublisherSelector(state);
  return selectedPublisher?.subscription?.productId === 'free';
}

export function selectedPublisherIsInGracePeriodSelector(state) {
  const selectedPublisher = selectedPublisherSelector(state);
  return (
    !selectedPublisherIsFreeSelector(state) &&
    (selectedPublisher?.subscription?.hasExpired ?? false)
  );
}

export function checkErrorOnMaxSuperboostableSelector(state) {
  const subscriptionChangeModal = requestSubscriptionChangeModalSelector(state);
  return (
    subscriptionChangeModal?.formValidationErrors?.maxSuperboostable === true
  );
}

export function selectedTypeOfClientSelector(state) {
  const selectedPlan = selectedPlanSelector(state);
  if (selectedPlan?.isPremium) {
    return selectedPlan.typeOfClient === MEXICO_TYPE_OF_CLIENT.AGENCIA
      ? MEXICO_TYPE_OF_CLIENT.AGENCIA_PREMIUM
      : MEXICO_TYPE_OF_CLIENT.REMATES_PREMIUM;
  }
  return selectedOptionsSelector(state)?.typeOfClient;
}

export function superboostCustomDiscountSelector(state) {
  return selectedOptionsSelector(state)?.superboostCustomDiscount;
}

export function subscriptionCustomDiscountSelector(state) {
  return selectedOptionsSelector(state)?.baseCustomDiscount;
}

export function superboostFinalPriceSelector(state) {
  return selectedOptionsSelector(state)?.superboostFinalPrice;
}

export function wasiPlanIdSelector(state) {
  return selectedOptionsSelector(state)?.wasiPlanId;
}

export function wasiPlanFinalPriceSelector(state) {
  return selectedOptionsSelector(state)?.wasiPlanFinalPrice;
}

export function wasiPlanCustomDiscountSelector(state) {
  return selectedOptionsSelector(state)?.wasiPlanCustomDiscount;
}

export function extraUsersSelector(state) {
  return selectedOptionsSelector(state)?.extraUsersQuantity;
}

export function extraUsersCustomDiscountSelector(state) {
  return selectedOptionsSelector(state)?.extraUsersCustomDiscount;
}

export function extraUsersFinalPriceSelector(state) {
  return selectedOptionsSelector(state)?.extraUsersFinalPrice;
}

export function subscriptionFinalPriceSelector(state) {
  return selectedOptionsSelector(state)?.baseFinalPrice;
}

export function availableTypeOfClientSelector(state) {
  const selectedTypeOfClient = selectedTypeOfClientSelector(state);

  if (
    subscriptionChangeTypeSelector(state) ===
      SUBSCRIPTION_CHANGE_TYPE.NEW_CONTRACT ||
    !selectedTypeOfClient
  ) {
    return [
      MEXICO_TYPE_OF_CLIENT.AGENCIA,
      MEXICO_TYPE_OF_CLIENT.AGENCIA_PREMIUM,
      MEXICO_TYPE_OF_CLIENT.REMATES,
      MEXICO_TYPE_OF_CLIENT.REMATES_PREMIUM,
    ];
  }
  return normalizeTypeOfClient(selectedTypeOfClient) ===
    MEXICO_TYPE_OF_CLIENT.AGENCIA
    ? [MEXICO_TYPE_OF_CLIENT.AGENCIA, MEXICO_TYPE_OF_CLIENT.AGENCIA_PREMIUM]
    : [MEXICO_TYPE_OF_CLIENT.REMATES, MEXICO_TYPE_OF_CLIENT.REMATES_PREMIUM];
}

function requestSubscriptionChangeModalSelector(state) {
  return state[REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE];
}

export function disableBaseSubscriptionCustomDiscountFieldsSelector(state) {
  const selectedPlanId = selectedPlanIdSelector(state);
  const currentPlanId = selectedPublisherSubscriptionSelector(state)?.productId;
  const isCurrentCycle =
    subscriptionChangeRequestTypeSelector(state) ===
    SUBSCRIPTION_CHANGE_TYPE.UPSELL_CURRENT_CYCLE;
  return selectedPlanId === currentPlanId && isCurrentCycle;
}

export function disableSuperboostCustomDiscountFieldsSelector(state) {
  const selectedSuperboost = selectedSuperboostingLimitSelector(state);
  const currentSuperboost =
    selectedPublisherSuperboostSelector(state)?.maxBoostableProperties ?? 0;
  const isCurrentCycle =
    subscriptionChangeRequestTypeSelector(state) ===
    SUBSCRIPTION_CHANGE_TYPE.UPSELL_CURRENT_CYCLE;
  return selectedSuperboost === currentSuperboost && isCurrentCycle;
}

export function disableWasiPlanCustomDiscountFieldsSelector(state) {
  return wasiPlanIdSelector(state) === undefined;
}

export function disableExtraUsersDiscountFieldsSelector(state) {
  return (
    extraUsersSelector(state) === undefined || extraUsersSelector(state) === ''
  );
}

export function wasiPlanSelector(state) {
  return state[WASI_PLANS_STATE_REGION] ?? [];
}

export function purchaseWasiSubscriptionEnabledSelector(state) {
  return (
    [
      SUBSCRIPTION_CHANGE_TYPE.NEW_CONTRACT,
      SUBSCRIPTION_CHANGE_TYPE.UPSELL_NEXT_CYCLE,
      SUBSCRIPTION_CHANGE_TYPE.DOWNGRADE_NEXT_CYCLE,
    ].includes(subscriptionChangeRequestTypeSelector(state)) &&
    selectedPublisherCountryCodeSelector(state) === 'MX' &&
    !selectedPublisherSelector(state).hasWasiSubscription
  );
}

export function availableUsersForWasiAccountSelector(state) {
  return (
    requestSubscriptionChangeModalSelector(state)?.usersForWasiAccount ?? []
  );
}

export function wasiAccountEmailSelector(state) {
  return selectedOptionsSelector(state)?.wasiAccountEmail ?? undefined;
}

export function hasWasiAccountErrorSelector(state) {
  return requestSubscriptionChangeModalSelector(state)?.accountError ?? false;
}

export function hasCurrentSubscriptionAndPendingToActivateNextCycleSelector(
  state
) {
  const selectedPublisher = selectedPublisherSelector(state);
  const planType = selectedPublisher?.subscription?.type;
  const scr = selectedPublisher?.subscriptionChangeRequest;
  return (
    planType === 'paid' &&
    scr?.type === SUBSCRIPTION_CHANGE_TYPE.UPSELL_NEXT_CYCLE &&
    (scr?.status === 'paid' || scr?.billingStatus === 'billed')
  );
}

export function generateUpsellCustomDiscountSectionTitleSelector(state) {
  const paymentMethod = selectedPaymentMethodSelector(state);
  const requestType = subscriptionChangeTypeSelector(state);

  if (
    requestType === SUBSCRIPTION_CHANGE_TYPE.UPSELL_CURRENT_CYCLE ||
    requestType === SUBSCRIPTION_CHANGE_TYPE.UPSELL_NEW_CYCLE
  ) {
    return 'For renewal';
  }

  if (requestType === SUBSCRIPTION_CHANGE_TYPE.UPSELL_CURRENT_CYCLE) {
    if (paymentMethod === CREDIT_CARD) {
      return 'For renewal';
    }
    return 'For remaining days';
  }

  return '';
}

export function showDisplayHelperTextSelector(state) {
  const requestType = subscriptionChangeTypeSelector(state);

  return (
    (getSummaryHelperTextSelector(state) != null &&
      requestType === SUBSCRIPTION_CHANGE_TYPE.NEW_CONTRACT) ||
    requestType === SUBSCRIPTION_CHANGE_TYPE.UPSELL_NEW_CYCLE ||
    requestType === SUBSCRIPTION_CHANGE_TYPE.UPSELL_CURRENT_CYCLE ||
    requestType === SUBSCRIPTION_CHANGE_TYPE.UPSELL_NEXT_CYCLE ||
    requestType === SUBSCRIPTION_CHANGE_TYPE.DOWNGRADE_NEXT_CYCLE
  );
}

export function getSummaryHelperTextSelector(state) {
  const selectedPublisherIsInGracePeriod =
    selectedPublisherIsInGracePeriodSelector(state);
  const hasSetAFixedPriceOrCustomDiscount =
    hasSetAFixedPriceOrCustomDiscountSelector(state);
  const isPriceLowerThanMinimum = isPriceLowerThanMinimumSelector(state);
  const isPriceHigherThanMaximum = isPriceHigherThanMaximumSelector(state);
  const requestType = subscriptionChangeTypeSelector(state);

  if (requestType === SUBSCRIPTION_CHANGE_TYPE.NEW_CONTRACT) {
    if (isPriceLowerThanMinimum) {
      return 'El porcentaje de descuento es mayor al admitido para este plan Premium.';
    }
    return null;
  }

  if (isPriceLowerThanMinimum) {
    return 'Para vender Proppit Premium, el nuevo precio mensual debe ser mayor al precio mensual que actualmente paga el cliente y el porcentaje de mínimo de upselling depende del tamaño del cliente. Prueba con un precio mayor.';
  }
  if (isPriceHigherThanMaximum) {
    return 'Renewal price is higher than listing price';
  }

  if (selectedPublisherIsInGracePeriod) {
    return 'Publisher subscription is in grace period, so a request of this type can not be created';
  }

  if (hasSetAFixedPriceOrCustomDiscount) {
    return 'As you have put a fixed price or a custom discount, the amount to be paid will NOT be recalculated each day.';
  }

  return '*Price will be subject to recalculation at the moment the client pays for the upsell.';
}

function hasSetAFixedPriceOrCustomDiscountSelector(state) {
  const subscriptionFinalPrice = subscriptionFinalPriceSelector(state);
  const superboostFinalPrice = superboostFinalPriceSelector(state);
  const subscriptionCustomDiscount = subscriptionCustomDiscountSelector(state);
  const superboostCustomDiscount = superboostCustomDiscountSelector(state);

  return (
    subscriptionFinalPrice ||
    superboostFinalPrice ||
    subscriptionCustomDiscount ||
    superboostCustomDiscount
  );
}

export function isUpsellWithRenewalPriceSelector(state) {
  const requestType = subscriptionChangeTypeSelector(state);

  return (
    requestType === SUBSCRIPTION_CHANGE_TYPE.UPSELL_CURRENT_CYCLE ||
    requestType === SUBSCRIPTION_CHANGE_TYPE.UPSELL_NEW_CYCLE
  );
}
